import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthLayoutComponent} from "./core/auth/auth-layout/auth-layout.component";
import {MainLayoutComponent} from "./shared/main-layout/main-layout.component";
import {PaginaNaoEncontradaComponent} from "./modules/erros/pagina-nao-encontrada/pagina-nao-encontrada.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: '**',
    component: PaginaNaoEncontradaComponent,
    loadChildren: () => import('./core/auth/auth.module').then((m) => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
