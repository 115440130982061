import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import Swal from 'sweetalert2';
import {ConfirmarDialogComponent} from "../componentes/confirmar-dialog/confirmar-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class AlertaService {
  private readonly configuracao: MatSnackBarConfig;

  constructor(private snackBar: MatSnackBar, public dlog: MatDialog, ) {
    this.configuracao = new MatSnackBarConfig();
    this.configuracao.duration = 4500;
  }

  mensagem(mensagem: string) {
    this.abrir(mensagem, ['alert-message']);
  }

  sucesso(mensagem: string) {
    this.abrir(mensagem, ['alert-success']);
  }

  erro(mensagem: string) {
    this.abrir(mensagem, ['alert-error']);
  }

  atencao(mensagem: string) {
    this.abrir(mensagem, ['alert-warning']);
  }

  private abrir(mensagem: string, classes: Array<string>, acao: string = '') {
    this.configuracao.panelClass = classes;
    this.snackBar.open(mensagem, acao, this.configuracao);
  }

  dialog(titulo, mensagem): Observable<any> {
    const dialogRef = this.dlog.open(ConfirmarDialogComponent, {
      width: '400px',
      data: {
        titulo,
        mensagem,
        btnOkText: 'Sim',
        btnCancelText: 'Não'
      }
    });
    return dialogRef.afterClosed();
  }

  /**
   * Dialog para confirmar ação
   * @param titulo Nome do item que será excluído
   * @returns uma Promise
   */
  dialogConfirma(titulo): Promise<boolean> {
    return new Promise<boolean>((resolver, rejeitar) => {
      Swal.fire({
        title: titulo,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          resolver(true);
        } else if (result.isDenied) {
          Swal.fire('Ação cancelada', '', 'error');
          rejeitar(false);
        }
      });
    });
  }

  /**
   * Dialog para confirmar ação
   * @param titulo Nome do item que será excluído
   * @returns uma Promise
   */
  dialogConfirmYorN(titulo): Promise<boolean> {
    return new Promise<boolean>((resolver, rejeitar) => {
      Swal.fire({
        title: titulo,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
      }).then((result) => {
        if (result.isConfirmed) {
          resolver(true);
        } else if (result.isDenied) {
          resolver(false);
        }
      });
    });
  }

  /**
   * Dialog para confirmar se vai querer alterar o status
   * @param ativo // Valor do status
   * @param titulo Nome do item que será excluído
   * @returns uma Promise
   */
  alterarStatus(ativo, titulo): Promise<boolean> {
    return new Promise<boolean>((resolver, rejeitar) => {
      Swal.fire({
        title: `Deseja ${ativo === true ? 'desativar' : 'ativar'} o <br> ${ativo}?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
      }).then((resultado) => {
        if (resultado.isConfirmed) {
          resolver(true);
        } else if (resultado.isDenied) {
          Swal.fire('Ação cancelada', '', 'error');
          rejeitar(false);
        }
      });
    });
  }
}
