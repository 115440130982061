
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import {AlertaService} from '../../../bibliotecaPjus/services/alerta.service';
import {NgxSpinnerService} from "ngx-spinner";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private alertaService: AlertaService,
        private authService: AuthService,
        private spinner: NgxSpinnerService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
              if(err.error) {
                if(err.error.key === 'LoginNaoAutorizadoPrimeiroAcesso') {
                  this.alertaService.erro(err.error.message);
                }
              } else {
                this.authService.removeAuthToken();
                this.router.navigate(['/auth/login']);
                this.alertaService.erro('Acesso expirado');
              }
              return throwError(err);
            }
            if (err.status === 403) {
              this.alertaService.erro('Sem permissão de acesso');
              this.router.navigate(['/auth/login']);
            }
            if (err.status === 400) {
              this.showErrorMsg(err);
              if(err.error.key == "UsuarioInexistente"){
                this.router.navigate(['/auth/login']);
              }
            }
            if (err.status === 500) {
              this.alertaService.erro('Erro!')
              return;
            }

            return throwError(err);
        }))
    }

    showErrorMsg(err: any){
      switch (err.error.key) {
        case 'CedenteCadastrado':
          err.error.message = 'Este precatório já está em processo de transmissão dentro da plataforma.';
          break;
        default:
          this.alertaService.erro(err.error.message);
          break;
      }
    }


}
