import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseProviderService {

  protected apiUrl: string = '';
  protected v1: string = 'V1';
  protected v2: string = 'V2';

  constructor(protected http: HttpClient) {
    this.apiUrl = environment.API;
  }

  protected getUrl(version: string, module: string, params: any[] = null): string {
    const par = params !== null && params.length > 0 ? '/' + params.join('/') : '';
    return `${this.apiUrl}/${version}/${module}${par}`;
  }
}
