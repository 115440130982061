import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import {MenuUserAvatarComponent} from "./menu-user-avatar/menu-user-avatar.component";
import {MatMenuModule} from "@angular/material/menu";
import { ChipComponent } from './chip/chip.component';
import {MaterialModule} from "../material.module";
import { FooterComponent } from './footer/footer.component';
import { SemPropostaComponent } from './sem-proposta/sem-proposta.component';
import { ChipStatusNegociacaoComponent } from './chip-status-negociacao/chip-status-negociacao.component';
import { BtnCopyComponent } from './btn-copy/btn-copy.component';
import { AlertaMsgComponent } from './alerta/alerta-msg/alerta-msg.component';
import { BadgeStatusComponent } from './badge-status/badge-status.component';
import { DetalhesPropostaComponent } from './proposta/detalhes-proposta/detalhes-proposta.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DetalhesNegociacaoComponent } from './proposta/detalhes-negociacao/detalhes-negociacao.component';
import { OffcanvasComponent } from './offcanvas/offcanvas.component';
import { FormularioNegociarPropostaComponent } from './proposta/acoes-proposta/formulario-negociar-proposta/formulario-negociar-proposta.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { customCurrencyMaskConfig } from 'src/app/aplicacao/ngx-mask/currency-mask';
import { FormularioAceitarPropostaComponent } from './proposta/acoes-proposta/formulario-aceitar-proposta/formulario-aceitar-proposta.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { FormularioCancelarPropostaComponent } from './proposta/acoes-proposta/formulario-cancelar-proposta/formulario-cancelar-proposta.component';
import { FormularioSolicitarNovoCalculoComponent } from './proposta/acoes-proposta/formulario-solicitar-novo-calculo/formulario-solicitar-novo-calculo.component';
import { AlertaDeFeedbackComponent } from './alerta-de-feedback/alerta-de-feedback.component';


@NgModule({
    declarations: [
      NavbarComponent,
      MenuUserAvatarComponent,
      ChipComponent,
      FooterComponent,
      SemPropostaComponent,
      ChipStatusNegociacaoComponent,
      BtnCopyComponent,
      AlertaMsgComponent,
      BadgeStatusComponent,
      DetalhesPropostaComponent,
      SpinnerComponent,
      DetalhesNegociacaoComponent,
      OffcanvasComponent,
      FormularioNegociarPropostaComponent,
      FormularioAceitarPropostaComponent,
      FormularioCancelarPropostaComponent,
      FormularioSolicitarNovoCalculoComponent,
      AlertaDeFeedbackComponent
    ],
  exports: [
    NavbarComponent,
    MenuUserAvatarComponent,
    ChipComponent,
    FooterComponent,
    SemPropostaComponent,
    ChipStatusNegociacaoComponent,
    BtnCopyComponent,
    AlertaMsgComponent,
    SpinnerComponent,
    BadgeStatusComponent,
    DetalhesPropostaComponent,
    DetalhesNegociacaoComponent,
    OffcanvasComponent,
    FormularioNegociarPropostaComponent,
    FormularioAceitarPropostaComponent,
    FormularioCancelarPropostaComponent,
    FormularioSolicitarNovoCalculoComponent,
    AlertaDeFeedbackComponent
  ],
    imports: [
        CommonModule,
        MatMenuModule,
        MaterialModule,
        NgxSpinnerModule,
        NgxMaskPipe,
        FormsModule,
        ReactiveFormsModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        NgxMaskDirective
    ]
})
export class ComponentesModule { }
