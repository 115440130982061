import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	baseUrl: string = environment.API;
	apiVersion: string = environment.V1;

	callTokenAuth(endpoint: string, redirect:any){
		sessionStorage.setItem('tsTokenEnd', endpoint);
		sessionStorage.setItem('tsTokenRedirect', redirect);
		this.router.navigate(['/auth/token-authentication']);
	}

	httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json",
    		"Accept": "application/json"
		})
	};

	getParamOptions(params:any){
		return {
			headers: this.httpOptions.headers,
			params: params
		}
	}



	constructor(
		private router: Router,
		private http: HttpClient,
		public loaderService: LoaderService,
	) { }

	navigate(route: any) {
		this.router.navigate(route);
	}

	post(url: string, data: any): Observable<any> {
		this.loaderService.showLoading();
		return this.http.post(url, data, this.httpOptions)
			.pipe(
				take(1),
				catchError((): Observable<any> => {
          return;
      }),
				finalize(()=>this.loaderService.hideLoading())
			)
	}

	put(url: string, data: any): Observable<any> {
		this.loaderService.showLoading();
		return this.http.put(url, data, this.httpOptions)
			.pipe(
				take(1),
				catchError(this.errorHandler),
				finalize(()=>this.loaderService.hideLoading())
			)
	}

	get(url: string, timeLoader=0, params:any=null): Observable<any> {
		this.loaderService.showLoading();
		if(params){
			this.httpOptions = this.getParamOptions(params)
		}
		return this.http.get(url, this.httpOptions)
			.pipe(
				take(1),
				catchError(this.errorHandler),
				finalize(() => {
					setTimeout(
						() => this.loaderService.hideLoading(), timeLoader
					)
				})
			)
	}

	getRouteName():string{
		return this.router.url;
	}

	// Manipulação de erros
	errorHandler(error: any) {
    let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Obter erro do lado do cliente
			errorMessage = error.error.message;
		} else {
			// Obter erro do lado do servidor
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

			if (error.status == 400) {
				alert(error.error.message);
			}

			if (error.status == 500) {
				errorMessage = 'Erro no Back-End: 500'
			}
		}
		return throwError(errorMessage);
	}

	exibeMensagem(msg: any, type: string) {
	}

	exibeMensagemErro(erro: any) {
		this.exibeMensagem(erro, 'danger');
	}

	onError(error: any) {
		this.exibeMensagemErro(error);
		return of();
	}
}
