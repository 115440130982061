import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Insere o cabeçalho Authorization nas requisições caso exista a chave 'AuthToken' no session storage//
    let tokenStorage = this.authService.getAuthToken();
    const cloneReq = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${tokenStorage ?? ''}`)
    });

    return next.handle(cloneReq)
      .pipe(
        tap((resp: HttpEvent<any>) => {
          if (resp instanceof HttpResponse) {
            this.authService.setAuthToken(resp.headers.get('x-token'));
          }
        })
      )

    //Se não houver a chave 'AuthToken' no session storage, apenas prossegue com a requisição//
    return next.handle(request);
  }
}
