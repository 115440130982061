import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-pagina-nao-encontrada',
  templateUrl: './pagina-nao-encontrada.component.html',
  styleUrls: ['./pagina-nao-encontrada.component.scss']
})
export class PaginaNaoEncontradaComponent {
  constructor(public route: Router,) {
  }
  irPara(): void {
    this.route.navigateByUrl('/');
  }
}
