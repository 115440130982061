<div class="container-fluid  bg-us h-100" style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
  <div class="row text-center">
    <div class="col-12">
      <div class="mb-4">
        <span class="text-uppercase txt-titulo-erro">Ops! não encontramos <br> essa página!</span>
      </div>

      <button class="btn btn-lg btn-primary" (click)="irPara()">Voltar para início</button>
    </div>
  </div>
</div>
